// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-page-templates-page-js": () => import("/opt/build/repo/src/page-templates/page.js" /* webpackChunkName: "component---src-page-templates-page-js" */),
  "component---src-page-templates-gallery-js": () => import("/opt/build/repo/src/page-templates/gallery.js" /* webpackChunkName: "component---src-page-templates-gallery-js" */),
  "component---src-page-templates-projects-js": () => import("/opt/build/repo/src/page-templates/projects.js" /* webpackChunkName: "component---src-page-templates-projects-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

